<template>
  <div class="yourpro__detail">

    <!-- HEADER -->
    <div class="yourpro__detail__header noselect" v-if="coach != null">
      <img :src="'https://app.sportlerplus.com/scaled/200/' + coach.avatar_image_url" alt="" />
      <h3 class="text-center">{{ coach.name }}</h3>
      <div class="yourpro__detail__quote">
        „{{ coach.quote }}“
      </div>
    </div>

    <!-- TABS -->
    <div class="tabs tabs--fullwidth">
      <div class="tabs__content">
        <v-btn v-if="coachTrainingItems.length > 0" @click="displayTab('workouts')" :class="activeDisplay=='workouts' ? 'is-active' : ''" text v-ripple="false">Workouts</v-btn>
        <v-btn v-if="coachArticles.length > 0" @click="displayTab('articles')" :class="activeDisplay=='articles' ? 'is-active' : ''" text v-ripple="false">Magazin</v-btn>
        <v-btn @click="displayTab('about')" :class="activeDisplay=='about' ? 'is-active' : ''" text v-ripple="false">About</v-btn>
      </div>
    </div>

    <div class="yourpro__detail__content">

      <!-- Workouts -->
      <div v-show="activeDisplay=='workouts'" class="tabs__item">
          <template v-for="ti in coachTrainingItems">
              <WorkoutItem v-if="activityType(ti) == 'workout.WorkoutPage'" @showPremiumHint=showPremiumHint :workout="ti" v-bind:key="ti.id"></WorkoutItem>
              <PlaylistItem v-else-if="activityType(ti) == 'exercise.PlaylistPage'" @showPremiumHint=showPremiumHint :playlist="ti" v-bind:key="ti.id"></PlaylistItem>
          </template>

      </div>

      <!-- Magazin -->
      <div v-show="activeDisplay=='articles'" class="tabs__item">
          <ArticleItem @showPremiumHint=showPremiumHint v-for="article in coachArticles" :key="article.id" :article="article"></ArticleItem>
      </div>

      <div v-show="activeDisplay=='about'" v-if="coach" class="tabs__item" v-html="coach.about">
      </div>

    </div>

    <PremiumHintModal></PremiumHintModal>
  </div>
</template>

<script>
import WorkoutItem from '@/components/WorkoutItem.vue';
import ArticleItem from '@/components/ArticleItem.vue';
import PlaylistItem from '@/components/PlaylistItem.vue';
import PremiumHintModal from '@/components/PremiumHintModal.vue';

export default {
    name: 'YourProDetail',
    components: { WorkoutItem, ArticleItem, PlaylistItem, PremiumHintModal },
    methods: {
        displayTab(name) {
            this.activeDisplay = name;
        },
        showPremiumHint() {
            this.$modal.show("premiumHint");
        },
        init() {
            this.coachId = parseInt(this.$route.params.id);
            this.coaches = this.$store.getters.getCoaches;
            this.articles = this.$store.getters.getArticles;
            this.workouts = this.$store.getters.getWorkouts;
            this.$store.dispatch('fetchPlaylists').then( () => {
                this.playlists = this.$store.getters.getPlaylists;
                let tabArgument = this.$route.query.tab;
                if ((tabArgument == "workouts") && (this.coachTrainingItems.length > 0)) {
                    this.displayTab("workouts");
                }
            });

            if (this.coachWorkouts.length == 0 && this.coachPlaylists.length == 0 && this.coachArticles.length > 0) {
                this.displayTab('articles');
            }
        },
        activityType(activity) {
			if (!activity) { return null; }
			if (activity.meta === undefined) { return "Category"; }
			else { return activity.meta.type; }
		},
    },
    mounted() {
        this.init();
    },
    computed: {
        coach() {
            return this.coaches.find(i => i.id == this.coachId);
        },
        coachArticles() {
            return this.articles.filter(a => a.coach != null && a.coach.id == this.coachId);
        },
        coachWorkouts() {
            return this.workouts.filter(w => w.coach != null && w.coach.id == this.coachId);
        },
        coachPlaylists() {
            return this.playlists.filter(p => p.coach != null && p.coach.id == this.coachId);
        },
        coachTrainingItems() {
            var coachPlaylists = this.playlists.filter(p => p.coach != null && p.coach.id == this.coachId);
            var coachWorkouts = this.workouts.filter(w => w.coach != null && w.coach.id == this.coachId);
            var trainingItems = coachPlaylists.concat(coachWorkouts);
            return trainingItems.sort((a, b) => {
                var d1 = new Date(a.go_live_at);
                var d2 = new Date(b.go_live_at);
                return d2 - d1;
             });
        }
    },
    data() {
        return {
            coachId: null,
            coaches: [],
            articles: [],
            workouts: [],
            playlists: [],
            activeDisplay: "workouts",
        }
    }
}
</script>

<style lang="scss">
  .yourpro__detail {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% + 32px);
      margin: -24px -16px 0;
      padding: 8px 20px 34px;
      box-sizing: border-box;

      img {
        align-self: center;
        margin-top: -8px;
        margin-bottom: 10px;
        width: 200px;
      }

      h3 {
        margin-bottom: 8px;
      }
    }

    &__quote {
      font-size: 14px;
      line-height: 24px;
      font-weight: 600;
      letter-spacing: -0.29px;
      color: $colorBlack;
      text-align: center;
    }

    &__content {
      display: flex;
      flex: 1;
      width: 100vw;
      transform: translateX(-50%);
      padding: 24px 0 62px;
      background: $colorBackground;
      margin: -20px 0 -62px 50%;
    }

    .tabs {

      &__item {
        display: flex;
        flex-direction: column;
        width: calc(100% - 40px);
        max-width: 520px;
        margin: 0px auto;

        p {
          font-size: 17px;
          line-height: 28px;
          font-weight: 600;
          letter-spacing: -0.4px;
        }
      }
    }
  }
</style>